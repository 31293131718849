@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Philosopher&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);



/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
} */
/* 
.contact-main p,
.contact-main textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.error-msg{
  color: red;
  font-size: 13px;
}

.contact-form-main ::placeholder {
  color: gray;
}

.contact-main {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.7) 3%,
    rgba(17, 16, 16, 0.6) 30%
  ),
    url("../../images/course-images/contact-b.jpg") center no-repeat
      fixed;
  background-size: cover;
  position: relative;
}

.contact-titles h3 {
  /* margin-bottom: 0%; *
  padding: 4.5%;
  font-weight: 600;
  color: rgba(12, 80, 163, 0.8);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.contact-titles {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.contact-titles .title-1 {
  text-align: left;
}

.contact-titles .title-2 {
  text-align: right;
}

.contact-form-main {
  display: flex;
  padding-top: 10%;
}

.contact-form-main .contact-form,
.map {
  width: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  /* margin: 5% 5%; *
}

.contact-form-main .contact-form{
  background-repeat: no-repeat;
  background: linear-gradient(
      rgba(255, 255, 255, 0.9) 2%,
      rgba(17, 16, 16, 0.4) 30%
    ),
    url("../../images/course-images/course-background.jpeg") center no-repeat;
  background-size: cover;
  background-color: white;
  position: relative;
}

.contact-form-main form {
  width: 90%;
  margin: auto;
  padding: 4%;
}

.contact-form form input,
textarea {
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  text-indent: 10px;
  color: black;
}

.contact-form form input{
  border-radius: 25px;
  padding: 1.7% 1%;
  margin: 1.5% 0;
  width: 100%;
}

.contact-form form textarea{
  border-radius: 0px 15px 0px 15px;
  padding-top: 3%;
  margin-top: 2%;
  width: 100%;
  height: 150px;
}

.form-map {
  padding: 5% 5%;
}

.contact-form form input[type="submit"] {
  background-color: rgba(12, 80, 163, 0.8);
  border: none;
  border-radius: 25px;
  padding: 2%;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form form input[type="submit"]:hover {
  background-color: #049434;
  transition: ease-in-out;
}

.map iframe {
  border: none;
  background-color: transparent;
}

.contact-mini-hr {
  background-color: rgba(215, 27, 4, 0.8);
  border: none;
  width: 2px;
  margin: 5% 0;
}

.contact-main-hr {
  background-color: rgba(1, 1, 1, 0.1);
  border: none;
  width: 10px;
}

/* Extra small devices (phones, 600px and down) *
@media only screen and (min-width: 400px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) *
@media only screen and (max-width: 600px) {
  .contact-form-main {
    display: block;
    padding-top: 30%;
    background-color: transparent;
  }

  .contact-form-main .contact-form,
  .map {
    width: 100%;
    background-color: transparent;
  }

  .contact-mini-hr {
    display: none;
  }

  .contact-titles .title-1 {
    text-align: center;
  }
  
  .contact-titles .title-2 {
    text-align: center;
  }

  .contact-titles h3 {
    /* margin-bottom: 0%; *
    padding: 2%;
    font-size: 20px;
  }

  .form-map {
    padding: 5% 5;
  }

  .contact-titles {
    width: 100%;
    display: block;
    margin-bottom: 10%;
  }

  .map{
    height: 300px;
    margin-top: 5%;
  }
}

@media only screen and (min-width: 640px) {
}

/* Medium devices (landscape tablets, 768px and up) *
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) *
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) 
@media only screen and (min-width: 1200px) {
} */

/* Jesse */

* {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
}

.body {
  box-sizing: border-box;
}

.section {
  padding-top: 150px;
  padding-bottom: 100px;
  background: linear-gradient(135deg,
      rgba(55, 95, 128, 0.85) 1%,
      rgba(81, 112, 170, 0.75) 30%),
    url(../../static/media/course-background.b8722593.jpeg) center no-repeat fixed;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-title-header {
  margin: 0px 40px;
}

.contact-title-header h1 {
  text-align: center;
  /* margin-bottom: 3%; */
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  margin: 15px 0;
  border-radius: 30px;
  font-size: 35px;
}

.contact-title-header h1::after {
  content: '';
  background: #fff;
  display: block;
  height: 3px;
  width: 150px;
  margin: 10px auto 30px;
}

.contact-title-header p {
  text-align: center;
  font-size: 18px;
  color: #fff;
}


.section .contactContainer {
  position: relative;
  /* min-width: 1100px; */
  width: 80%;
  min-height: 550px;
  display: flex;
  margin-top: 50px;
  /* z-index: 1000; */
}



.section .contactContainer .contactInfo {
  position: abolute;
  top: 40px;
  /* width: 350px; */
  /* height: calc(100% - 80px); */
  background: #0c50a3;
  z-index: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
}

.section .contactContainer .contactInfo h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.section .contactContainer .contactInfo .info {
  position: relative;
  margin: 20px 0;
}

.section .contactContainer .contactInfo .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  /* cursor: pointer; */
  align-items: flex-start;
}

.section .contactContainer .contactInfo .info li span img {
  transition: all 0.5s ease;
}

.section .contactContainer .contactInfo .info li div p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}


.section .contactContainer .contactInfo .info li span:nth-child(1) {
  width: 30px;
  min-height: 30px;
}

.section .contactContainer .contactInfo .info li span:nth-child(1) img {
  max-width: 30px;
  filter: invert(1);
  /* opacity: 0.5; */
}

.section .contactContainer .contactInfo .info li span:nth-child(2) {
  color: #fff;
  /* margin-left: 10px; */
  font-weight: 300;
  /* opacity: 0.5; */
}

.section .contactContainer .contactInfo .info li:hover span:nth-child(1) img,
.section .contactContainer .contactInfo .info li:hover span:nth-child(2) {
  /* opacity: 1; */
  filter: invert(0);
}

.section .contactContainer .contactInfo .sci {
  position: relative;
  display: flex;

}

.section .contactContainer .contactInfo .sci li {
  list-style: none;
  margin-right: 15px;
}

.section .contactContainer .contactInfo .sci li a {
  text-decoration: none;
}

.section .contactContainer .contactInfo .sci li a img {
  max-width: 50px;
  filter: invert(1);
  /* opacity: 0.5; */
  transition: all 0.5s ease;

}

.section .contactContainer .contactInfo .sci li:hover a img {
  /* opacity: 1; */
  filter: invert(0);
}

.error-msg{
  color: red;
  font-size: 13px;
}

.section .contactContainer .contactForm {
  position: abolute;
  padding: 50px 50px 10px 50px;
  background: #fff;
  /* margin-left: 150px; */
  /* padding-left: 250px; */
  /* width: calc(100% - 150px); */
  /* width: 80%; */
  height: 100%;
  /* height: auto; */
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 900px) {
  .section .contactContainer .contactInfo {
    width: 400px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .section .contactContainer .contactForm {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .section .contactContainer {
    display: block;
  }

  .center-hrz-contactInfo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section .contactContainer .contactForm h2 {
    text-align: center;
  }

  .section .contactContainer .contactInfo {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .section .contactContainer .contactForm {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .section .contactContainer .contactInfo h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .section .contactContainer {
    display: block;
    width: 90%;
  }
}

.section .contactContainer .contactForm h2 {
  color: #0c50a3;
  font-size: 24px;
  font-weight: 500;
}

.section .contactContainer .contactForm .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

.section .contactContainer .contactForm .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}

.section .contactContainer .contactForm .formBox .inputBox.w50 {
  width: 47%;
}

.section .contactContainer .contactForm .formBox .inputBox.w100 {
  width: 100%;
}

.section .contactContainer .contactForm .formBox .inputBox input,
.section .contactContainer .contactForm .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  border-bottom: 1px solid #777;
  outline: none;

}

.section .contactContainer .contactForm .formBox .inputBox textarea {
  min-height: 150px; /* was 120px */

}

.section .contactContainer .contactForm .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  transition: 0.3s;
  pointer-events: none;
}

.section .contactContainer .contactForm .formBox .inputBox input:focus~span,
.section .contactContainer .contactForm .formBox .inputBox textarea:focus~span,
.section .contactContainer .contactForm .formBox .inputBox input:valid~span,
.section .contactContainer .contactForm .formBox .inputBox textarea:valid~span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #90d91e;
}

.section .contactContainer .contactForm .formBox .inputBox input[type="submit"] {
  position: relative;
  cursor: pointer;
  background: #0c50a3;
  color: #fff;
  border: none;
  border-radius: 25px;
  max-width: 150px;
  padding: 12px;
  transition: all 0.5s ease;
  width: 100% !important;
}


.section .contactContainer .contactForm .formBox .inputBox input[type="submit"]:hover {
  background: #90d91e;
}

.contact-main-hr {
  background-color: rgba(1, 1, 1, 0.1);
  border: none;
  width: 10px;
}

.map {
  width: 80%;
  margin: 70px auto;
  height: 500px;
  border-radius: 10px;
}

.map iframe {
  border-radius: 10px;
  outline: none;
  border: none;
}

@media only screen and (max-width: 500px) {
.map {
  width: 90%;
}
}

.center-hrz-contact {
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.registration-title-header h1 {
  text-align: center;
  font-weight: 600;
  color: #fff;
  margin: 15px 0;
  border-radius: 30px;
  font-size: 35px;
}

.registration-title-header h1::after {
  content: '';
  background: #fff;
  display: block;
  height: 3px;
  width: 150px;
  margin: 10px auto 30px;
}

p,
input,
span,
option {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #000;
}

.main-container {

  background: linear-gradient(
      135deg,
      rgba(76, 143, 93, 0.85) 1%,
      rgb(76, 143, 93, 0.75) 30%
    ),
    url(../../static/media/registration-b.d33822ec.jpg) center no-repeat fixed;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
}

.registration-main {
  margin: 100px auto;
  width: 70%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  background: #fff;
  padding: 50px 0px;
}

.registration-main .registration-form .form {
  grid-gap: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.registration-main h1 {
  text-align: center;
  font-weight: 600;
  color: #000;
  margin: 15px 0px 60px 0px;
  border-radius: 30px;
  font-size: 35px;
}

.registration-main h1::after {
  content: '';
  background: #000;
  display: block;
  height: 3px;
  width: 150px;
  margin: 10px auto 30px;
}

.registration-main .read-more-span {
  border: none;
  background-color: rgba(76, 143, 93, 0.85);
  font-size: 15px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  /* color: #333; */
  color: #fff;
  /* border: #f3f3f3 1.1px solid; */
  border-radius: 9px;
  transition: 0.5s ease-in-out;
}

.read-more-span:hover {
  /* color: #fff; */
  background-color: #0c50a3;
  transform: translateY(-2.3px);
}

.terms-conditions {
  /* border: red 1px dotted; */

  font-weight: lighter;
}

.course-select {
  /* border: red 2px dotted; */
  background-color: #fff;
  border-radius: 10px;
  /* border: #333 solid 1.5px; */
  width: 100%;
}

.terms-p {
  /* border: red 1px dotted; */
  color: #333;

  text-align: justify;
  text-justify: inter-word;
}

.registration-main a {
  color: #ffc500;
  margin: 0 5px;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
}

.proof-pay {
  margin-top: 20px;
}

.select input {
  font-family: "Montserrat", sans-serif;
}

.registration-main input[type="file"] {
  border-bottom: none;
}

.input-icons {
  margin: auto;
  font-size: 20px;
  color: gray;
}

.form-input {
  border: #333 1.5px solid;

  display: flex;
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  /* border: #1fd418 solid 1px; */
}

.input-icon {
  margin: auto;
  font-size: 20px;
  color: gray;
}

.registration-main input[type="submit"] {
  border: red 1px solid;
  background-color: rgba(30, 84, 149, 0.8);
  border-bottom: none;
  width: 100px;
  height: 70px;
  color: white;
  font-size: 18px;
  /* border-radius: 9px; */
  margin-top: 10px;
}

.registration-main input[type="submit"]:hover {
  background-color: #049434;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.registration-form ::placeholder {
  color: gray;
}

/* .registration-main p {
  color: white;
} */
select option:disabled {
  font-family: "Montserrat", sans-serif;
}

.registration-main .error {
  color: red;
  font-size: 13px;
  /* padding: 5px; */
}

.registration-main .registration-form {
  margin: auto;
  width: 90%;
  background: transparent;
}

.registration-form .form input,
select {
  width: 100%;
  height: 30px;
  border: none;
  /* padding: 1.7% 1%; */
  outline: none;
  /* margin: 1.7% 0; */
  /* border-bottom: 1.5px dotted #2c3494; */
  color: rgb(63, 59, 59);
  border-radius: 10px;
  text-indent: 10px;
  background-color: #fff;
}

#select-code {
  background-color: transparent;
}

.country-code {
  background-color: #fff;
  padding: 0% 2.5%;
  width: 95%;
  border-radius: 10px;
}

.agree #agree-1 {
  width: 50px;
  height: 15px;
}

.agree {
  width: 100%;
}

.form-file ::-webkit-file-upload-button {
  background: rgba(12, 80, 163, 0.65);
  color: white;
  padding: 0.6em 1em;
  border: none;
  cursor: pointer;
  border-radius: 45px;
}

.submitBtn {
  background-color: rgba(30, 84, 149, 0.8);
  border: none;
  padding: 20px 50px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.form-file ::-webkit-file-upload-button:hover {
  background: #049434;
  color: white;
  padding: 0.6em 1em;
  border: none;
  cursor: pointer;
  border-radius: 45px;
}

/* .proof{
  color: #bf0000;
  background-color: black;
} */

.registration-form .bank-title {
  color: #fff;
}

.registration-form .form .main-input-fields {
  display: block;
  justify-content: space-between;
}

.form-input-fields-p1,
.form-input-fields-p2 {
  width: 100%;
}

.registration-form .form-input {
  margin: 20px 0;
}

.form-input-fields-p1 {
  margin: 0 1% 0 0;
}

.form-input-fields-p2 input {
  padding: 10px 0;
}

.form-input-fields-p1 input {
  padding: 10px 0;
}

.form-file #myFile {
  padding: 6% 4%;
  width: 90%;
  border: none;
  padding: 20px 5%;
  border-radius: 15px;
  background-color: #f3f3f3;
  margin: 20px 0;
  /* border: #f3f3f3 2px solid; */

}

.registration-form .bold {
  font-weight: bold;
  color: #333;
}

.form-file span {
  color: #bf0000;
  font-weight: 500;
}

.bank-details {
  width: 75%;
  padding: 2%;
}
.bank-details .bank-information {
  margin: 0 auto;
  padding: 1% 3%;
  list-style: none;
  width: 60%;
  min-height: 200px;
  border: red 2px dotted;
  background-color: #f3f3f3;
}
.bank-details .bank-information li {
  display: flex;
  /* justify-content: space-between; */
  border: red 2px dotted;
  /* padding: 10px 5px; */
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.bank-details .bank-information li span {
  border: none;
  padding: 2px 4px;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-weight: 600;
  border-radius: 6px;
  transition: ease-out 0.5s;
}

.bank-details .bank-information li span:hover {
  /* transform: translateX(13px); */
  background: linear-gradient(#20bf55 5.5%, #01baef 100%);
  color: #fff;
}

.terms-p {
  border: red 2px;
  /* margin: 0 auto; */
}
.agree-bank-details hr {
  background-color: rgb(155, 152, 152);
  border: none;
  width: 2px;
  margin: 0 15px;
}

.agree .agree-checkbox {
  display: flex;
}
.agree {
  /* width: 80%; */
  display: block;
}

.agree-bank-details {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.registration-main input[type="file"] {
  width: 50%;
  /* color: white; */
}

@media only screen and (min-width: 400px) {
  .registration-main .registration-form .form {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .registration-form .form .main-input-fields {
    display: block;
  }

  .form-input-icon {
    padding: 5px;
    margin: 5px 0;
  }

  .registration-main input[type="submit"] {
    width: 100%;
    padding: 10px;
    border-radius: 45;
    background-color: #0c50a3;
    color: white;
  }

  .registration-main input[type="file"] {
    width: 100%;
  }

  .agree-bank-details {
    display: block;
    justify-content: space-evenly;
  }

  .form .bank-details {
    width: 100%;
    margin: 2% 0;
    padding: 0;
  }

  .registration-main h1 {
    /* color: #0c50a3;
    font-size: 20px; */
    text-align: center;
    /* padding-top: 10px 0; */
  }

  .registration-form {
    /* background-color: tomato; */
    padding: 0%;
  }


  .registration-main .registration-form .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    background: transparent;
    border-radius: none;
  }

  .registration-main .registration-form {
    margin: auto;
    width: 90%;
    /* background-color: tomato; */
  }

  .registration-form .form input {
    width: 100%;
  }

  .registration-main option {
    font-size: 10;
  }

  .form-container {
    width: 90%;
    background: transparent;
  }
  .registration-main {
    width: 90%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .form-container {
    background: transparent;
    width: 90%;
    margin: auto;
    padding-bottom: 3%;
    padding-top: 1%;
  }

  .registration-main .registration-form .form {
    grid-gap: 20px;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .registration-main input[type="submit"] {
    padding: 2%;
    border-radius: 40px;
    margin-bottom: 40px;
    background-color: #0c50a3;
    color: white;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .registration-main input[type="submit"] {
    border-radius: 40px;
    margin-bottom: 40px;
  }
}

.center-hrz-registration {
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.number p{
    text-align: center;
}

.student-data-form{
    padding-top: 10%;
    margin-bottom:10%
}

table {
  /* font-family: "Montserrat", sans-serif; */
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
    font-size: 15px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

* {
  padding: 0;
  margin: 0;
}

.top-scroll button {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 35px;
  height: 35px;
  background-color: #6fb73f;
  padding: 5px;
  color: white;
  font-size: 22px;
  border: none;
  z-index: 3;
  cursor: pointer;
}

.top-scroll button:hover {
  background-color: #049434;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) {
  button {
    bottom: 30px;
  }
}

.searchInputs {
  margin-top: 60px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
}

.search input {
  background-color: white;
  font-size: 18px;
  width: 350px;
  border: none;
  padding-left: 15px;
}

.searchIcon {
  padding: 15px;
  background-color: #0c50a3;
  display: grid;
  place-items: center;
  color: white;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 20px;
}

.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  padding: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
.dataLink {
  text-decoration: none;
}

.dataLink:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
}

.Research {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-image: linear-gradient(90deg, #410076, #8820dd); */
}

.search-main h1 {
  font-family: "Abel", sans-serif;
  text-align: center;
  line-height: 360px;
  color: white;
}

/* *,*::before,*::after {
    box-sizing: border-box;
} */

/* acts as the body tag*/
.body- {
    box-sizing: border-box;
    font-weight: 500;
    margin: 0;
    background-color: #07410d;
    background-image: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgb(55, 221, 66) 0%, rgb(3, 0, 190) 100%);
    padding-top: 10em;
    padding-bottom: 5em;
}

/* styling for the top part of the page*/

.header- {
    font-family: 'Raleway', sans-serif;
    color: rgb(212, 223, 255);
    text-shadow: 2px 2px 4px rgb(5, 25, 80);
}

.header- > h2 {
    font-size: 4em;
    text-align: center;
}

.header- > p {
    font-size: 2em;
    text-align: center;
    color: inherit;
}

/* general styling for the main section*/ 
.container- {
    max-width: 90%;
    margin: 0 auto;
}

.row- {
    display: flex;
}

.column- {
    display: grid;
}

a {
    text-decoration: none;
}

:is(h2, h3) {
    margin: 0;
}

.cards- {
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 1em;
    gap: 1em;
}

.card- {
    position: relative;
    max-width: 210px;
    min-height: 300px;
    overflow: hidden;
}

.card- i {
    font-size: 2.5rem;
}

.icon-title- {
    grid-gap: 2em;
    gap: 2em;
    text-align: center;
    border: 2px solid #fff;
    padding: 3.5em 3em;
    color: #fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

.icon-title- h3 {
    font-size: 16px;
}

.card-content- {
    position: absolute;
    top: 0;
    background-image: url(../../static/media/home_1.a953faf7.jpg), linear-gradient(rgb(182, 216, 227), rgb(182, 216, 227), rgb(188, 225, 186));
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
    background-color: rgb(158, 173, 221);
    box-shadow: 0px 6px 20px rgba(0, 255, 244, 0.2);
    padding: 0 1em;
    align-items: center;
    opacity: 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transform: translateY(-310px);
    -webkit-transform: translateY(-310px);
    -moz-transform: translateY(-310px);
    -ms-transform: translateY(-310px);
    -o-transform: translateY(-310px);
    transition: 0.3s ease-in-out, opacity 0.4s ease-in;
    -webkit-transition: 0.3s ease-in-out, opacity 0.4s ease-in;
    -moz-transition: 0.3s ease-in-out, opacity 0.4s ease-in;
    -ms-transition: 0.3s ease-in-out, opacity 0.4s ease-in;
    -o-transition: 0.3s ease-in-out, opacity 0.4s ease-in;
}

.title- {
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    color: #fff;
    text-shadow: 1px 1px 7px rgb(0, 44, 242);
}

.title- h2 {
    font-size: 16px;
}

.title-btn- {
    justify-content: center;
    grid-gap: 2em;
    gap: 2em;
}

.title-btn- h3 {
    font: 14px;
    color: #fff;
    text-shadow: 1px 1px 7px #000;
    text-align: center;
}

.btn- {
    font-size: 14px;
    color: #fff;
    background: #6fb73f;
    padding: 0.7em 1em;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
}

.btn-:hover {
    color: rgb(7, 135, 0);
}

.card-:hover .card-content- {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
