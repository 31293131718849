@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.registration-title-header h1 {
  text-align: center;
  font-weight: 600;
  color: #fff;
  margin: 15px 0;
  border-radius: 30px;
  font-size: 35px;
}

.registration-title-header h1::after {
  content: '';
  background: #fff;
  display: block;
  height: 3px;
  width: 150px;
  margin: 10px auto 30px;
}

p,
input,
span,
option {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #000;
}

.main-container {

  background: linear-gradient(
      135deg,
      rgba(76, 143, 93, 0.85) 1%,
      rgb(76, 143, 93, 0.75) 30%
    ),
    url('../../assets/images/registration-b.jpg') center no-repeat fixed;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
}

.registration-main {
  margin: 100px auto;
  width: 70%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  background: #fff;
  padding: 50px 0px;
}

.registration-main .registration-form .form {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.registration-main h1 {
  text-align: center;
  font-weight: 600;
  color: #000;
  margin: 15px 0px 60px 0px;
  border-radius: 30px;
  font-size: 35px;
}

.registration-main h1::after {
  content: '';
  background: #000;
  display: block;
  height: 3px;
  width: 150px;
  margin: 10px auto 30px;
}

.registration-main .read-more-span {
  border: none;
  background-color: rgba(76, 143, 93, 0.85);
  font-size: 15px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  /* color: #333; */
  color: #fff;
  /* border: #f3f3f3 1.1px solid; */
  border-radius: 9px;
  transition: 0.5s ease-in-out;
}

.read-more-span:hover {
  /* color: #fff; */
  background-color: #0c50a3;
  transform: translateY(-2.3px);
}

.terms-conditions {
  /* border: red 1px dotted; */

  font-weight: lighter;
}

.course-select {
  /* border: red 2px dotted; */
  background-color: #fff;
  border-radius: 10px;
  /* border: #333 solid 1.5px; */
  width: 100%;
}

.terms-p {
  /* border: red 1px dotted; */
  color: #333;

  text-align: justify;
  text-justify: inter-word;
}

.registration-main a {
  color: #ffc500;
  margin: 0 5px;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
}

.proof-pay {
  margin-top: 20px;
}

.select input {
  font-family: "Montserrat", sans-serif;
}

.registration-main input[type="file"] {
  border-bottom: none;
}

.input-icons {
  margin: auto;
  font-size: 20px;
  color: gray;
}

.form-input {
  border: #333 1.5px solid;

  display: flex;
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  /* border: #1fd418 solid 1px; */
}

.input-icon {
  margin: auto;
  font-size: 20px;
  color: gray;
}

.registration-main input[type="submit"] {
  border: red 1px solid;
  background-color: rgba(30, 84, 149, 0.8);
  border-bottom: none;
  width: 100px;
  height: 70px;
  color: white;
  font-size: 18px;
  /* border-radius: 9px; */
  margin-top: 10px;
}

.registration-main input[type="submit"]:hover {
  background-color: #049434;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.registration-form ::placeholder {
  color: gray;
}

/* .registration-main p {
  color: white;
} */
select option:disabled {
  font-family: "Montserrat", sans-serif;
}

.registration-main .error {
  color: red;
  font-size: 13px;
  /* padding: 5px; */
}

.registration-main .registration-form {
  margin: auto;
  width: 90%;
  background: transparent;
}

.registration-form .form input,
select {
  width: 100%;
  height: 30px;
  border: none;
  /* padding: 1.7% 1%; */
  outline: none;
  /* margin: 1.7% 0; */
  /* border-bottom: 1.5px dotted #2c3494; */
  color: rgb(63, 59, 59);
  border-radius: 10px;
  text-indent: 10px;
  background-color: #fff;
}

#select-code {
  background-color: transparent;
}

.country-code {
  background-color: #fff;
  padding: 0% 2.5%;
  width: 95%;
  border-radius: 10px;
}

.agree #agree-1 {
  width: 50px;
  height: 15px;
}

.agree {
  width: 100%;
}

.form-file ::-webkit-file-upload-button {
  background: rgba(12, 80, 163, 0.65);
  color: white;
  padding: 0.6em 1em;
  border: none;
  cursor: pointer;
  border-radius: 45px;
}

.submitBtn {
  background-color: rgba(30, 84, 149, 0.8);
  border: none;
  padding: 20px 50px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.form-file ::-webkit-file-upload-button:hover {
  background: #049434;
  color: white;
  padding: 0.6em 1em;
  border: none;
  cursor: pointer;
  border-radius: 45px;
}

/* .proof{
  color: #bf0000;
  background-color: black;
} */

.registration-form .bank-title {
  color: #fff;
}

.registration-form .form .main-input-fields {
  display: block;
  justify-content: space-between;
}

.form-input-fields-p1,
.form-input-fields-p2 {
  width: 100%;
}

.registration-form .form-input {
  margin: 20px 0;
}

.form-input-fields-p1 {
  margin: 0 1% 0 0;
}

.form-input-fields-p2 input {
  padding: 10px 0;
}

.form-input-fields-p1 input {
  padding: 10px 0;
}

.form-file #myFile {
  padding: 6% 4%;
  width: 90%;
  border: none;
  padding: 20px 5%;
  border-radius: 15px;
  background-color: #f3f3f3;
  margin: 20px 0;
  /* border: #f3f3f3 2px solid; */

}

.registration-form .bold {
  font-weight: bold;
  color: #333;
}

.form-file span {
  color: #bf0000;
  font-weight: 500;
}

.bank-details {
  width: 75%;
  padding: 2%;
}
.bank-details .bank-information {
  margin: 0 auto;
  padding: 1% 3%;
  list-style: none;
  width: 60%;
  min-height: 200px;
  border: red 2px dotted;
  background-color: #f3f3f3;
}
.bank-details .bank-information li {
  display: flex;
  /* justify-content: space-between; */
  border: red 2px dotted;
  /* padding: 10px 5px; */
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.bank-details .bank-information li span {
  border: none;
  padding: 2px 4px;
  background-color: #fff;
  color: #333;
  font-size: 15px;
  font-weight: 600;
  border-radius: 6px;
  transition: ease-out 0.5s;
}

.bank-details .bank-information li span:hover {
  /* transform: translateX(13px); */
  background: linear-gradient(#20bf55 5.5%, #01baef 100%);
  color: #fff;
}

.terms-p {
  border: red 2px;
  /* margin: 0 auto; */
}
.agree-bank-details hr {
  background-color: rgb(155, 152, 152);
  border: none;
  width: 2px;
  margin: 0 15px;
}

.agree .agree-checkbox {
  display: flex;
}
.agree {
  /* width: 80%; */
  display: block;
}

.agree-bank-details {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.registration-main input[type="file"] {
  width: 50%;
  /* color: white; */
}

@media only screen and (min-width: 400px) {
  .registration-main .registration-form .form {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .form-container {
    width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .registration-form .form .main-input-fields {
    display: block;
  }

  .form-input-icon {
    padding: 5px;
    margin: 5px 0;
  }

  .registration-main input[type="submit"] {
    width: 100%;
    padding: 10px;
    border-radius: 45;
    background-color: #0c50a3;
    color: white;
  }

  .registration-main input[type="file"] {
    width: 100%;
  }

  .agree-bank-details {
    display: block;
    justify-content: space-evenly;
  }

  .form .bank-details {
    width: 100%;
    margin: 2% 0;
    padding: 0;
  }

  .registration-main h1 {
    /* color: #0c50a3;
    font-size: 20px; */
    text-align: center;
    /* padding-top: 10px 0; */
  }

  .registration-form {
    /* background-color: tomato; */
    padding: 0%;
  }


  .registration-main .registration-form .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    background: transparent;
    border-radius: none;
  }

  .registration-main .registration-form {
    margin: auto;
    width: 90%;
    /* background-color: tomato; */
  }

  .registration-form .form input {
    width: 100%;
  }

  .registration-main option {
    font-size: 10;
  }

  .form-container {
    width: 90%;
    background: transparent;
  }
  .registration-main {
    width: 90%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .form-container {
    background: transparent;
    width: 90%;
    margin: auto;
    padding-bottom: 3%;
    padding-top: 1%;
  }

  .registration-main .registration-form .form {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .registration-main input[type="submit"] {
    padding: 2%;
    border-radius: 40px;
    margin-bottom: 40px;
    background-color: #0c50a3;
    color: white;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .registration-main input[type="submit"] {
    border-radius: 40px;
    margin-bottom: 40px;
  }
}

.center-hrz-registration {
  display: flex;
  justify-content: center;
  align-items: center;
}