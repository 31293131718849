@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher&display=swap");

* {
  padding: 0;
  margin: 0;
}

.Research {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-image: linear-gradient(90deg, #410076, #8820dd); */
}

.search-main h1 {
  font-family: "Abel", sans-serif;
  text-align: center;
  line-height: 360px;
  color: white;
}
